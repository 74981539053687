<template>
  <v-app>
    <v-container fluid class="pa-0">
      <div id="queEs" ref="queEs" class="full-height full-width bg-image first-screen">
        <v-row class="d-flex align-center justify-center container-1">
          <v-col cols="12" sm="8" class="d-flex justify-start screen-1-join-container">
            <v-col cols="12" sm="5" class="pista-box-container">
              <v-card class="text-center px-4 px-sm-10 py-5 pista-card">
                <v-img src="../assets/logoPista.png" max-width="150" class="mx-auto mt-5 image"></v-img>

                <v-divider class="my-2"></v-divider>

                <v-card-text class="px-4">
                  <div class="blue--text text-uppercase font-weight-medium mb-2">Move - Improve - Connect</div>
                  <div class="mb-2 pista-card-text">Pushing the limits of human performance through personalized AI training plans.</div>
                </v-card-text>
                <v-btn color="primary" class="mx-auto px-6 py-6" href="https://forms.gle/VGgupDt1LThsGEdc6" target="_blank">
                  Join Now
                  <v-icon class="ml-1">mdi-arrow-right</v-icon>
                </v-btn>
              </v-card>
            </v-col>
          </v-col>
          <v-col cols="12" sm="8" class="d-flex what-is-container" style="background-color: white; gap: 2rem">
            <v-col cols="12" sm="4" class="d-flex justify-center align-center swiming-background">
              <!-- <div class="swiming-background"> -->
              <v-responsive aspect-ratio="16/9" style="border-radius: 10px">
                <div class="d-flex justify-center">
                  <iframe
                    id="video"
                    style="border-radius: 10px"
                    width="350"
                    height="197"
                    src="https://www.youtube.com/embed/8oAuN04f3cA?si=HQWwEPTv-dDUd0vK"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
              </v-responsive>
              <!-- </div> -->
            </v-col>
            <v-col>
              <div class="blue--text text-uppercase font-weight-medium mb-2">AI POWERED</div>

              <h1 class="mb-5">¿About PISTA?</h1>
              <P>
                PISTA is a fitness app that generates personalized training plans tailored to specific activities and goals, utilizing AI for
                real-time tracking through wearables to understand health data and optimize performance.
              </P>
              <span> Train, post activities, and build your community! </span>
            </v-col>
          </v-col>
        </v-row>
      </div>
      <div id="actividades" class="full-height full-width bg-image second-screen">
        <v-col cols="12" sm="4" md="6" xl="4" class="screen-2-container">
          <h1 class="mobile-only title-2">Coming Soon! Are you ready?</h1>
          <p class="mobile-only px-8 mt-2 mb-5">
            We are preparing to launch.<br />
            Be the first to know and join the wait list!
          </p>

          <v-col class="btn2-container" cols="12" md="9" xl="12">
            <v-btn
              color="white"
              class="mx-auto px-sm-0 py-md-5 py-8 py-xl-8 screen-2-button"
              href="https://forms.gle/VGgupDt1LThsGEdc6"
              target="_blank"
            >
              Join the wait list!
              <v-icon class="ml-3">mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
        </v-col>
        <!-- <h1>Actividades</h1>
        <p>Contenido sobre las actividades...</p> -->
      </div>
      <!-- <div id="beneficios" class="full-height full-width">
        <h1>Beneficios</h1>
        <p>Contenido sobre los beneficios...</p>
      </div>
      <div id="comunidad" class="full-height full-width">
        <h1>Comunidad</h1>
        <p>Contenido sobre la comunidad...</p>
      </div>
      <div id="faq" class="full-height">
        <h1>FAQ</h1>
        <p>Contenido sobre las preguntas frecuentes...</p>
      </div> -->
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'HelloWorld',
  mounted() {
    this.$refs.queEs.scrollIntoView({ behavior: 'smooth' });
  },
  data: () => ({
    dat: null
  })
};
</script>

<style scoped>
html,
body {
  /* width: 140% !important;
  overflow-x: hidden !important; */
}
.full-height {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  box-sizing: border-box;
}

.container-1 {
  row-gap: 5rem;
}

.screen-1-join-container {
  position: relative;
  top: 1rem;
  /* left: 6rem; */
  gap: 3rem;
}
.pista-card {
  background-color: rgba(28, 28, 28, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
  color: white;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  max-width: 450px;
}

.pista-card-text {
  color: white;
  letter-spacing: 2.5px;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.7rem;
}

.v-card-title {
  letter-spacing: 2px;
}

.v-card-text .blue--text {
  color: #1e88e5 !important;
  letter-spacing: 1px;
}

.v-btn {
  background-color: #1e88e5;
  color: white;
  border-radius: 24px;
  text-transform: none;
  width: 70%;

  margin-bottom: 1rem;
}

.what-is-container {
  border-radius: 24px;
  position: relative;
  left: 2rem;
  bottom: 1rem;

  text-align: left;
  letter-spacing: 2.5px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.7rem;

  box-shadow: 0 5px 20px 1px rgba(30, 136, 229, 0.4);
}

.swiming-background {
}

.bg-image {
  background-size: 100% 100%; /* Estira la imagen horizontalmente y mantiene la altura original */
  /* background-position: -5rem center; */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
}

.first-screen {
  background-image: url('@/assets/pantalla1.png');
}
.second-screen {
  height: 100vh;
  background-size: 100% 100%;
  background-image: url('@/assets/pantalla2Fondo.png');
}

.screen-2-button {
  color: #2196f3;
  font-weight: 500;
  /* line-height: 1rem; */
  /* position: relative;
  bottom: 11rem; */
  /* font-size: 1.2rem; */

  letter-spacing: 2.5px;
  /* font-weight: 300; */
  font-size: 1rem;
  line-height: 1.7rem;
}

.screen-2-container {
  position: relative;
  bottom: 16rem;
  color: white;
  letter-spacing: 2.5px;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.7rem;
}

.mobile-only {
  /* display: none; */
}

@media (max-width: 600px) {
  html,
  body {
    width: 100% !important;
    overflow-x: hidden !important; /* Evita el desplazamiento horizontal */
    touch-action: pan-y !important; /* Desactiva el desplazamiento horizontal en pantallas táctiles */
  }

  /* v-col {
    padding-left: 0;
    padding-bottom: 0;
  } */
  .container-1 {
    row-gap: 2rem;
  }

  .bg-image {
    background-size: 100% 66%;
  }

  .full-height {
    /* height: 130vh; */
    /* width: 100%; */
    height: 160vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .pista-card-text {
    /* font-size: 0.8rem;
    line-height: 1.4rem; */
  }

  .screen-1-join-container {
    top: 0;
  }

  .pista-box-container {
    display: flex;
    justify-content: center;
  }
  .pista-card {
    background-color: rgba(28, 28, 28, 0.8);
    max-width: 80%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(10px);
  }

  .v-btn {
    /* width: auto; */
    padding: 0.5rem 1rem;
  }

  .what-is-container {
    position: unset;
    flex-direction: column-reverse;
    max-width: 73%;
    font-weight: 400;
  }
  .screen-2-button {
    width: auto;
    /* font-size: 0.9rem; */
  }

  .swiming-background {
    /* background-image: url('../assets/swiming.png');
    background-position: center;
    background-size: cover;
    height: 150px; */
  }

  .second-screen {
    height: 115vh;
    background-size: 350% 100%;
    background-image: url('@/assets/pantalla2Fondo.png');
  }

  .mobile-only {
    display: block;
  }

  .screen-2-container {
    bottom: 16rem;
    font-weight: 500;

    color: white;
    letter-spacing: 2.5px;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.7rem;
  }

  .title-2 {
    line-height: 2.5rem;
  }

  /* .col-12 {
    padding: 0 !important; 
  } */
  .row {
    margin: 0 !important;
  }

  #video {
    width: 215px !important;
    height: 120px !important;
  }
}

@media (max-width: 400px) {
  .full-height {
    height: 180vh;
  }

  .second-screen {
    height: 125vh;
  }
}

@media (min-width: 900px) and (max-width: 1400px) and (min-height: 500px) and (max-height: 800px) {
  .container-1 {
    row-gap: 2rem;
    max-width: 100%;
  }

  .screen-1-join-container {
    position: relative;
    top: 1rem;
    left: -0.5rem;
    gap: 1rem;
  }
  .pista-card-text {
    color: white;
    letter-spacing: 1px;
    font-weight: 300;
    font-size: 0.8rem;
    line-height: 0.8rem;
  }

  .pista-card {
    background-color: rgba(28, 28, 28, 0.3);
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
    color: white;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    max-width: 300px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 10px !important;
  }

  .image {
    max-width: 30% !important;
  }

  .what-is-container {
    border-radius: 24px;
    position: relative;
    left: 1rem;
    bottom: 1rem;

    text-align: left;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 0.8rem;

    box-shadow: 0 5px 20px 1px rgba(30, 136, 229, 0.4);
  }

  .second-screen {
    height: 100vh;
    background-size: 100% 100%;
    background-image: url('@/assets/pantalla2Fondo.png');
  }

  .screen-2-button {
    color: #2196f3;
    font-weight: 500;

    letter-spacing: 1px;
    /* font-weight: 300; */
    font-size: 0.8rem;
    line-height: 0.8rem;
  }

  .btn2-container {
    margin: auto;
    margin-top: -1rem;
  }

  .screen-2-container {
    position: relative;
    bottom: 9rem;
    letter-spacing: 1px;
    font-size: 0.8rem;
    line-height: 1.5rem;
    margin-top: 1rem;
  }

  #video {
    width: 214px !important;
    height: 120px !important;
  }
}
</style>
