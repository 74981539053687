<template>
  <v-app-bar app dark color="black">
    <!-- Menú de navegación para pantallas grandes -->
    <v-toolbar-title>
      <v-img src="path-to-your-logo.png" alt="Logo" contain max-width="40"></v-img>
    </v-toolbar-title>
    <v-spacer></v-spacer>

    <!-- Menú de navegación para pantallas grandes -->
    <div class="menu-md">
      <v-btn text class="white--text text" @click="scrollTo('queEs')">¿About?</v-btn>
      <v-btn text class="white--text text" @click="scrollTo('actividades')">Activity</v-btn>
      <v-btn text class="white--text text" @click="scrollTo('beneficios')">Benefit</v-btn>
      <v-btn text class="white--text text" @click="scrollTo('comunidad')">Community</v-btn>
      <v-btn text class="white--text text" @click="scrollTo('faq')">FAQ</v-btn>
      <v-btn class="join-btn ml-5" color="primary" href="https://forms.gle/VGgupDt1LThsGEdc6" target="_blank">Join</v-btn>
    </div>

    <!-- Botón de menú para pantallas pequeñas -->
    <div class="menu-sm">
      <v-menu v-model="menu" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="scrollTo('queEs')">
            <v-list-item-title>About</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo('actividades')">
            <v-list-item-title>Activity</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo('beneficios')">
            <v-list-item-title>Benefit</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo('comunidad')">
            <v-list-item-title>Community</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo('faq')">
            <v-list-item-title>FAQ</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: 'TopBar',
  data() {
    return {
      menu: false
    };
  },
  methods: {
    scrollTo(section) {
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
};
</script>

<style scoped>
.join-btn {
  background-color: #1e88e5;
  color: white;
  border-radius: 24px;
  text-transform: none;
}

.text {
  color: white;
  letter-spacing: 2.5px;
  font-weight: 400;
  font-size: 1rem;
  text-transform: none;
}

/* Oculta el menú de navegación para pantallas pequeñas */
.menu-sm {
  display: none;
}

/* Muestra el menú de navegación para pantallas grandes */
.menu-md {
  display: flex;
}

/* Estilo para el menú desplegable */
.v-menu__content {
  top: 64px; /* Ajusta esto según el tamaño de tu app-bar */
}

@media (max-width: 600px) {
  .menu-md {
    display: none;
  }

  .menu-sm {
    display: flex;
  }
}

@media (min-width: 900px) and (max-width: 1400px) and (min-height: 500px) and (max-height: 800px) {
  .join-btn {
    background-color: #1e88e5;
    color: white;
    border-radius: 24px;
    text-transform: none;
  }

  .text {
    color: white;
    letter-spacing: 2.5px;
    font-weight: 400;
    font-size: 0.8rem;
    text-transform: none;
  }
}
</style>
